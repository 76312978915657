import React from 'react'
import { Heading } from '../components/Heading'
import { HeadingLevel } from 'baseui/heading'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { useStyletron, BaseProvider } from 'baseui'
import Logo from '../svg/ohshelp-orange.svg'
import { ourColors } from '../components/Colors'
import { ParagraphLarge, ParagraphMedium, ParagraphSmall } from 'baseui/typography'
import { VimeoVideo } from '../components/VimeoVideo'
import '../components/layout.css'
import '../components/landing-page.css'
import { LogoFooter } from '../components/LogoFooter'
import useInspectlet from '../hooks/inspectlet'
import { theme } from '../components/Layout'
import { StyleObject } from 'styletron-react'
import { RegistrationForm } from '../components/RegistrationForm'
import { ExternalLinkButton, LinkButton } from '../components/LinkButton'
import { Tab, Tabs } from '../components/Tabs'

const headingStyle: StyleObject = {
  marginTop: '35.5px',
  marginBottom: '32px',
  fontFamily: 'Montserrat',
  fontSize: '26px',
  lineHeight: '36px',
  fontWeight: 'bold',
  letterSpacing: '-0.5px'
}

const textStyle = {
  fontFamily: 'Roboto',
  fontSize: '18px',
  lineHeight: '28px',
  display: 'flex',
  justifyContent: 'center',
  color: ourColors.copyGrey
}

const LandingPageHeader = () => {
  const [css] = useStyletron()
  const logoStyles = css({
    paddingTop: '16px',
    paddingBottom: '5px',
    '@media screen and (max-width: 800px)': {
      paddingLeft: '12px'
    }
  })

  return (
    <header>
      <div
        className={css({
          background: 'white'
        })}
      >
        <Grid gridMaxWidth={1230} gridMargins={[16, 36, 64]}>
          <Cell span={[2.5, 4]} align={ALIGNMENT.start}>
            <div className={logoStyles}>
              <Logo />
            </div>
          </Cell>
          <Cell span={1.5} skip={[0, 0.5]} align={ALIGNMENT.center}>
            <div className={css({ display: 'flex', justifyContent: 'flex-end' })}>
              <LinkButton
                to="/login"
                className={css({
                  width: '125px'
                })}
              >
                Login
              </LinkButton>
            </div>
          </Cell>
        </Grid>
      </div>
    </header>
  )
}

const OrangeBox = () => {
  const [css] = useStyletron()

  return (
    <div className={css({ backgroundColor: ourColors.utechOrange, paddingBottom: '100px' })}>
      <Grid gridColumns={[4, 8, 12]} gridMaxWidth={1230} gridMargins={[16, 36, 64]}>
        <Cell span={[3.25, 4, 5]}>
          <div
            className={css({
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            })}
          >
            <Heading
              overrides={{
                Block: {
                  style: {
                    ...headingStyle,
                    ...{ color: 'white' }
                  }
                }
              }}
            >
              OHShelp - the all-in-one tool for a safer, healthier workplace.
            </Heading>
          </div>
        </Cell>
        <Cell span={[0, 0, 1]} />
        <Cell span={[4, 4, 6]}>
          <div
            data-testid="video-parent"
            className={css({
              maxWidth: '545px',
              '@media only screen and (min-width: 600px)': { marginTop: '40px' },
              '@media only screen and (min-width: 800px)': { marginTop: '67px' }
            })}
          >
            <VimeoVideo videoUrl="https://player.vimeo.com/video/472082785" desktopSpacing={0} />
          </div>
        </Cell>
      </Grid>
    </div>
  )
}

interface TitledParagraphInterface {
  readonly children?: React.ReactNode | readonly React.ReactNode[]
  heading: string
  video_url?: string
  headingBottomMargin?: string
  desktopTextWidth: number
  justifyContentDesktop?: string
}

const TitledParagraph = (props: TitledParagraphInterface) => {
  const [css] = useStyletron()
  return (
    <HeadingLevel>
      {props.video_url && (
        <Cell span={[4, 8, 7]} align={ALIGNMENT.center}>
          <div className={css({ marginBottom: '32px', marginTop: '32px' })}>
            <VimeoVideo videoUrl={props.video_url} desktopSpacing={0} />
          </div>
        </Cell>
      )}
      <Cell span={[4, 8, props.desktopTextWidth]} align={ALIGNMENT.center}>
        <Heading
          overrides={{
            Block: {
              style: {
                fontFamily: 'Montserrat',
                fontSize: '18px',
                lineHeight: '28px',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                '@media only screen and (min-width: 1136px)': {
                  justifyContent: props.justifyContentDesktop || 'center'
                },
                color: ourColors.copyGrey,
                marginBottom: '15px',
                marginTop: '33px',
                textAlign: 'center'
              }
            }
          }}
        >
          {props.heading}
        </Heading>
        <div
          className={css({
            fontFamily: 'Roboto',
            fontSize: '18px',
            lineHeight: '28px',
            color: ourColors.copyGrey
          })}
        >
          {props.children}
        </div>
      </Cell>
    </HeadingLevel>
  )
}

interface LandingPageParagraphProps {
  readonly children?: React.ReactNode | readonly React.ReactNode[]
  textAlign?: 'left'
  additionalStyles?: StyleObject
}

const LandingPageParagraph = (props: LandingPageParagraphProps) => {
  return (
    <ParagraphMedium
      overrides={{
        Block: {
          style: {
            ...textStyle,
            ...{
              paddingLeft: '16px',
              paddingRight: '16px',
              textAlign: props.textAlign || 'center'
            },
            ...props.additionalStyles
          }
        }
      }}
    >
      {props.children}
    </ParagraphMedium>
  )
}

interface WhiteBoxProps {
  readonly children?: React.ReactNode | readonly React.ReactNode[]
}

const WhiteBox = (props: WhiteBoxProps) => {
  const [css] = useStyletron()

  return (
    <div
      className={css({
        backgroundColor: 'white',
        '@media only screen and (min-width: 1136px)': {
          marginTop: '-56px',
          marginLeft: '64px',
          marginRight: '64px',
          width: 'calc(100% - 128px)'
        },
        '@media only screen and (max-width: 1136px)': {
          marginTop: '-65px',
          marginLeft: '27.5px',
          marginRight: '27.5px',
          width: 'calc(100% - 55px)'
        },
        '@media only screen and (max-width: 800px)': {
          marginTop: '-16px',
          marginLeft: '16px',
          marginRight: '16px',
          width: 'calc(100% - 36px)'
        },
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        paddingTop: '17px',
        paddingBottom: '30px',
        position: 'relative'
      })}
    >
      {props.children}
    </div>
  )
}

const WhyUseOHSHelp = () => {
  return (
    <Grid gridColumns={[4, 8, 12]} gridMaxWidth={1230} gridMargins={0}>
      <HeadingLevel>
        <Cell span={[4, 8, 12]} align={ALIGNMENT.center}>
          <Heading
            overrides={{
              Block: {
                style: {
                  ...headingStyle,
                  ...{ display: 'flex', justifyContent: 'center' }
                }
              }
            }}
          >
            OHShelp – the support you need, when you need it
          </Heading>
        </Cell>
        <TitledParagraph
          heading={'Access OHS Tips and Guides'}
          headingBottomMargin="15px"
          desktopTextWidth={4}
        >
          <LandingPageParagraph>
            OHShelp brings together the resources you need to resolve health and safety issues in
            your workplace. Browse easy to find, easy to follow OHS guides and hazard information.
          </LandingPageParagraph>
        </TitledParagraph>
        <TitledParagraph
          heading={'Log and Track Issues on the Go'}
          headingBottomMargin="15px"
          desktopTextWidth={4}
        >
          <LandingPageParagraph>
            Use OHShelp to keep a personal record of OHS issues at your workplace. Store photos,
            make notes and email issues to your boss or workmates, all from the one app.
          </LandingPageParagraph>
        </TitledParagraph>
        <TitledParagraph
          heading={'Connect to Union Support'}
          headingBottomMargin="15px"
          desktopTextWidth={4}
        >
          <LandingPageParagraph>
            OHShelp is union built to provide more support to members. Use it to stay connected to
            the latest news and training opportunities from your union.
          </LandingPageParagraph>
        </TitledParagraph>
      </HeadingLevel>
    </Grid>
  )
}

interface SpacedListProps {
  contents: string[]
}

const SpacedList = (props: SpacedListProps) => {
  const [css] = useStyletron()
  return (
    <div className={css({ marginBottom: '67px' })}>
      {props.contents.map((x, index) => (
        <ParagraphSmall
          key={index}
          overrides={{
            Block: {
              style: {
                ...textStyle,
                marginBottom: '18px',
                justifyContent: 'start',
                '@media only screen and (max-width: 1136px)': {
                  justifyContent: 'center',
                  textAlign: 'center'
                }
              }
            }
          }}
        >
          {x}
        </ParagraphSmall>
      ))}
    </div>
  )
}

const WhatCanOHSHelpDo = () => {
  const [css] = useStyletron()
  return (
    <div
      className={css({
        backgroundColor: ourColors.backgroundGrey,
        '@media only screen and (min-width: 800px)': {
          marginTop: '-73px',
          paddingTop: '73px',
          paddingBottom: '73px'
        },
        '@media only screen and (max-width: 800px)': { marginTop: '-193px', paddingTop: '193px' },
        paddingTop: '17px',
        display: 'flex',
        width: '100%'
      })}
    >
      <Grid gridColumns={[4, 8, 12]} gridMaxWidth={1230} gridMargins={[16, 36, 64]}>
        <HeadingLevel>
          <Cell span={[4, 8, 12]} align={ALIGNMENT.center}>
            <Heading
              overrides={{
                Block: {
                  style: {
                    ...headingStyle,
                    ...{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '45px',
                      marginBottom: '8px'
                    }
                  }
                }
              }}
            >
              OHShelp – built to help you get on with the job
            </Heading>
          </Cell>
          <TitledParagraph
            heading={'Identify, assess and resolve OHS issues'}
            video_url={'https://player.vimeo.com/video/472083038'}
            headingBottomMargin="25px"
            desktopTextWidth={5}
            justifyContentDesktop="start"
          >
            <SpacedList
              contents={[
                'Search information on workplace hazards',
                'Follow simple steps to resolving OHS issues',
                'Find relevant legislation and essential resources'
              ]}
            />
          </TitledParagraph>
          <TitledParagraph
            heading={'Log issues that you or your workmates notice'}
            video_url={'https://player.vimeo.com/video/472083265'}
            headingBottomMargin="25px"
            desktopTextWidth={5}
            justifyContentDesktop="start"
          >
            <SpacedList
              contents={[
                'Log an OHS issue in seconds',
                'Store photos and notes ',
                'Email issues to your boss or workmates'
              ]}
            />
          </TitledParagraph>
          <TitledParagraph
            heading={'Stay up to date with your OHS Rights'}
            video_url={'https://player.vimeo.com/video/472083159'}
            headingBottomMargin="25px"
            desktopTextWidth={5}
            justifyContentDesktop="start"
          >
            <SpacedList
              contents={[
                'Follow step by step guides to OHS processes',
                'Find quick links to OHS laws and regulations',
                'Access regularly updated OHS resources'
              ]}
            />
          </TitledParagraph>
        </HeadingLevel>
      </Grid>
    </div>
  )
}

const LandingPageForm = () => {
  const [css] = useStyletron()

  return (
    <>
      <WhiteBox>
        <HeadingLevel>
          <Grid>
            <Cell span={[4, 8, 12]}>
              <Heading
                overrides={{
                  Block: {
                    style: { ...headingStyle, ...{ textAlign: 'center', marginBottom: '7px' } }
                  }
                }}
              >
                Sign up to OHShelp
              </Heading>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <LandingPageParagraph
                additionalStyles={{
                  '@media only screen and (min-width: 1136px)': { fontWeight: 'bold' }
                }}
              >
                To sign up you will need to be:
              </LandingPageParagraph>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <div className={css({ display: 'flex', justifyContent: 'center' })}>
                <ul
                  className={css({
                    paddingRight: '32px',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '18px',
                    color: ourColors.copyGrey,
                    listStylePosition: 'inside'
                  })}
                >
                  <li className={css({ marginBottom: '10px' })}>A paid union member</li>
                  <li>Working in Victoria</li>
                </ul>
              </div>
            </Cell>
            <Cell span={[4, 8, 12]}>
              <div
                className={css({
                  '@media only screen and (min-width: 600px)': {
                    fontWeight: 'bold'
                  }
                })}
              >
                <LandingPageParagraph>
                  Enter your details to get started on OHShelp
                </LandingPageParagraph>
              </div>
            </Cell>
          </Grid>
          <RegistrationTabs />
        </HeadingLevel>
      </WhiteBox>
    </>
  )
}

const RegistrationTabs = () => {
  const [activeKey, setActiveKey] = React.useState('0')
  return (
    <Grid>
      <Cell span={[4, 6]} align={ALIGNMENT.start}>
        <Tabs
          onChange={({ activeKey }) => {
            setActiveKey(activeKey.toString())
          }}
          activeKey={activeKey}
        >
          <Tab title="Union members">
            <RegistrationForm maxColumns={4} maxWidth={410} />
          </Tab>
          <Tab title="Non union members">
            <NonMemberContent />
          </Tab>
        </Tabs>
      </Cell>
    </Grid>
  )
}

const NonMemberContent = () => {
  const [css] = useStyletron()
  return (
    <>
      <ParagraphLarge>
        OHShelp is built by unions for union members to help improve health and safety for all
        workers.
      </ParagraphLarge>
      <ParagraphLarge marginBottom="47px">
        Join your union today to access OHShelp and other essential union support.
      </ParagraphLarge>
      <ExternalLinkButton
        className={css({ paddingLeft: '30px', paddingRight: '30px', width: '188px' })}
        href="https://join.australianunions.org.au/begin-join?utm_campaign=ohshelp&utm_source=ohshelp&utm_medium=signup_form"
      >
        Join your union
      </ExternalLinkButton>
    </>
  )
}

const LandingPageContent = () => {
  return (
    <HeadingLevel>
      <OrangeBox />
      <WhiteBox>
        <WhyUseOHSHelp />
      </WhiteBox>
      <WhatCanOHSHelpDo />
      <LandingPageForm />
    </HeadingLevel>
  )
}

export const LandingPage = () => {
  useInspectlet()
  return (
    <BaseProvider theme={theme}>
      <LandingPageHeader />
      <LandingPageContent />
      <LogoFooter marginTop="0px" />
    </BaseProvider>
  )
}

export default LandingPage
